import axios from '../../../utils/API';
import { navigate } from '../../../helpers/routerHistory';
import setAuthToken from '../../../utils/setAuthToken';
import useHashCrypt from '../../../helpers/useHashCrypt';
import { getLanguage } from './appActions';
import { getStatus, getPriority } from './projectActions';
import moment from 'moment';
// import _ from 'lodash';


// Login - Get User Token
export const loginUser = ({username, password, rememberMe, authtype, redirect}, dispatch, actionMsg, userStoreLang) => {
    let userGlobal
    dispatch({ type: 'START_LOADING' })
    axios
        // .post('/api/v1/login', {username, password, authtype})
        .post('/auth/login', {email: username, password: password}) // auth profil
        .then(res => {

            console.log(res.data)

            // const userDatabaseLang = res.data.data[0].hasOwnProperty('language')
            // const userDatabaseVersion = res.data.hasOwnProperty('languageVersion')
            // organize groups & users hierarchy
            // let group = res.data.tree
            // let userInfo = {id: res.data.data[0].id, name: res.data.data[0].name}
            // let userGroupFilter = handleUserGroupsFilter(group, userInfo)
            // let orgGroup = Object.keys(res.data.data[0].OrganizationGroup).map(i => res.data.data[0].OrganizationGroup[i])
            // let userAllUsers = handleUserAllUsers(userGroupFilter)

            // const token = 'Bearer '+res.data.token
            // const user = {
            //     id: res.data.data[0].id,
            //     DisplayName: res.data.data[0].DisplayName,
            //     Name: res.data.data[0].name,
            //     email: res.data.data[0].email,
            //     userEAV: res.data.data[0].EAV,
            //     Organization: res.data.data[0].Organization,
            //     Group: orgGroup,
            //     UserGroupTree: res.data.tree,
            //     UserGroupFilter: userGroupFilter,
            //     UserAllUsers: userAllUsers,
            //     permissions: res.data.data[0].Permission,
            //     expiresIn: res.data.expiresIn,
            //     message: res.data.message,
            //     token: token,
            //     language: !userDatabaseLang ? userStoreLang.language : res.data.language,
            //     languageVersion: !userDatabaseVersion ? userStoreLang.languageVersion : res.data.languageVersion
            // }

            // console.log(user)

            const token = 'Bearer '+res.data.jwt
            const user = {
                id: res.data.data.id,
                DisplayName: res.data.data.username,
                Name: res.data.data.username,
                email: res.data.data.email,
                // expiresIn: res.data.expiresIn,
                UserRole: res.data.data.UserRole[0],
                Organization: {defaultAdminGroup: "79",
                                defaultCostBookGuestGroup: "82",
                                defaultProjectGroup: "81",
                                defaultUserGroup: "80",
                                description: "Profil Klett company",
                                dmsRootFolder: "1027",
                                id: "33",
                                name: "PROFIL KLETT d.o.o.",
                                navigation: "blue",
                                redirect: "/offers",
                                register: "0",
                                url: "profil.iv-dev.hr"},
                                permissions: {"profil_offers": true, "profil_customers": true, "profil_products": true, "profil_gratis": true},
                message: res.data.message,
                token: token,
            }
            userGlobal = user
            setAuthToken(token);
            dispatch({ type: 'LOGIN', payload: user})
            let rememberObject = {checked: rememberMe, username: username, password: useHashCrypt(password, true)}
            rememberMe ? dispatch({ type: 'REMEMBER_ME', payload: rememberObject}) : dispatch({ type: 'FORGET_ME'})

            return res
        })
        .then((res) => {
            // example ticket
            // dispatch({ type: 'GET_TICKETS', payload: [{id: 1, name: 'ticket1'}, {id: 2, name: 'ticket2'}, {id: 3, name: 'ticket3'}] })
            // cost book
            // dispatch({ type: 'GET_DATA', payload: [{id: 1, name: 'cost-book-1'}, {id: 2, name: 'cost-book-2'}, {id: 3, name: 'cost-book-3'}] })
            // projects
            // dispatch({ type: 'GET_PROJECTS', payload: [{id: "1", title: "Project 1", parent: null, root: true,}, {id: "3", title: "Faza 1", parent: 1}] })

            // user language
            // afterSaveUserLanguage(res, userStoreLang, dispatch)
        })
        .then(() => {
            // console.log('userGlobal.Organization', userGlobal.Organization)
            // project management - status & priority
            if(userGlobal.Organization.hasOwnProperty('hasStatus') && userGlobal.Organization.hasStatus === '1') {
                getStatus({organizationId: parseInt(userGlobal.Organization.id)})
                .then((res) => {dispatch({ type: 'SET_STATUS', payload: res.map(o=> ({ ...o, label: o.name, value: o.name})) })})
                .catch((err) => {console.log('getStatus', err)})
            }
            if(userGlobal.Organization.hasOwnProperty('hasPriority') && userGlobal.Organization.hasPriority === '1') {
                getPriority({organizationId: parseInt(userGlobal.Organization.id)})
                .then((res) => {dispatch({ type: 'SET_PRIORITY', payload: res.map(o=> ({ ...o, label: o.name, value: o.name})) })})
                .catch((err) => {console.log('getPriority', err)})
            }
        })
        .then(() => {
            // Organization Redirect
            if(userGlobal.Organization.hasOwnProperty('redirect') && userGlobal.Organization.redirect) {
                navigate(userGlobal.Organization.redirect)
            } else {
                navigate('/')
            }
            // custom prop redirect
            // if(redirect) {navigate(redirect)} else {navigate('/')}
            dispatch({ type: 'STOP_LOADING' })
        })
        .catch(err => {
            console.log(err)
            navigate('/')
            dispatch({ type: 'STOP_LOADING' })
            if(err.response) actionMsg(err.response.data.message)
        })
}

// Login - Get User Token
export const registerUser = ({email, password}, dispatch, actionMsg, userStoreLang, organization, name) => {
    let organizationId = parseInt(organization.id)
    dispatch({ type: 'START_LOADING' })
    axios
        .post('/api/v1/register', {organizationId, email, password, EAV: [{name: 'name', type: 'Text', value: name}, {name: 'language', type: 'Text', value: 'en'}]})
        .then(() => {
            actionMsg('Confirmation email sended', 'success')
        })
        .then(() => {
            dispatch({ type: 'STOP_LOADING' })
        })
        .catch(err => {
            dispatch({ type: 'STOP_LOADING' })
            // register error msg
            let msg = err.response.data.message
            // email unique msg
            if(err.response.data.error && err.response.data.error.errors && err.response.data.error.errors[0].message) msg = err.response.data.error.errors[0].message
            actionMsg(msg)
        })
}

// activate user
export async function activateUser(email, activationToken) {
    let response = await axios.post('/api/v1/activate', { email, activationToken})
    return await response.data.data
}

// reset user password
export async function resetUserPassword(email, passwordToken, password) {
    let response = await axios.post('/api/v1/password', { email, passwordToken, password})
    return await response.data.data
}

// Log user out
export const logoutUser = (dispatch) => {
    dispatch({ type: 'START_LOADING' })
    dispatch({ type: 'LOGOUT' })
    navigate('/')
    dispatch({ type: 'STOP_LOADING' })
}

// Check url - organization params
export async function checkHostOrganizationParams(hostname) {
    let response = await axios.get('/api/v1/public/organization', { params: {url: hostname} })
    return await response.data.data
}

// google login (with auto registration if not)
export async function thirdPartyLoginRegister(email, password, dispatch, actionMsg, userStoreLang, organization, name, authtype) {
    // try login user
    dispatch({ type: 'START_LOADING' })
    axios
    .post('/api/v1/login', {username: email, password: password, authtype: authtype})
    .then(res => {
        // set user data - language
        afterSaveUser(res, userStoreLang, dispatch)
        .then((res) => {
            // get user language strings
            afterSaveUserLanguage(res, userStoreLang, dispatch)
            .then(() => {})
            .catch(err => {console.log('store user language', err)})
        })
        .catch(err => {console.log(err)})
    })
    .catch(() => {
        // if failed login - register user
        let organizationId = parseInt(organization.id)

        axios
        .post('/api/v1/register', {organizationId, email, password, authtype, EAV: [{name: 'name', type: 'Text', value: name}, {name: 'language', type: 'Text', value: 'en'}]})
        .then(() => {
            // login user after register
            axios
            .post('/api/v1/login', {username: email, password: password, authtype: authtype})
            .then(res => {
                // set user data - language
                afterSaveUser(res, userStoreLang, dispatch)
                .then((res) => {
                    // get user language strings
                    afterSaveUserLanguage(res, userStoreLang, dispatch)
                    .then(() => {})
                    .catch(err => {console.log(err)})
                })
                .catch(err => {console.log(err)})
            })
            .catch(err => {console.log('after user register then login err', err)})
        })
        .catch(() => {
            actionMsg('Wrong authentication method')
            dispatch({ type: 'STOP_LOADING' })
        })
    })
}

// helper function for google login user
async function afterSaveUser(res, userStoreLang, dispatch) {
    const userDatabaseLang = res.data.data[0].hasOwnProperty('language')
    const userDatabaseVersion = res.data.hasOwnProperty('languageVersion')
    // organize groups & users hierarchy
    let group = res.data.tree
    let userInfo = {id: res.data.data[0].id, name: res.data.data[0].name}
    let userGroupFilter = handleUserGroupsFilter(group, userInfo)

    const token = 'Bearer '+res.data.token
    const user = {
        id: res.data.data[0].id,
        DisplayName: res.data.data[0].DisplayName,
        Name: res.data.data[0].name,
        email: res.data.data[0].email,
        userEAV: res.data.data[0].EAV,
        Organization: res.data.data[0].Organization,
        Group: res.data.data[0].Group,
        UserGroupTree: res.data.tree,
        UserGroupFilter: userGroupFilter,
        permissions: res.data.data[0].Permission,
        expiresIn: res.data.expiresIn,
        message: res.data.message,
        token: token,
        language: !userDatabaseLang ? userStoreLang.language : res.data.language,
        languageVersion: !userDatabaseVersion ? userStoreLang.languageVersion : res.data.languageVersion
    }
    setAuthToken(token);
    dispatch({ type: 'LOGIN', payload: user})
    dispatch({ type: 'STOP_LOADING' })
    return res
}

// helper function for google login user - language
async function afterSaveUserLanguage(res, userStoreLang, dispatch) {

    let lang = res.data.language !== null ? res.data.language : userStoreLang.language
    getLanguage({language: lang})
    .then((resLang) => {
        let checkLangVersion = moment(userStoreLang.languageVersion).isBefore(moment(resLang.version))
        if(resLang.language !== userStoreLang.language || checkLangVersion) {
            // update user language store
            let langStoreArray = []
            for(const r of resLang.data) {langStoreArray.push(r)}
            dispatch({ type: 'GET_LANGUAGE_STRINGS', payload: langStoreArray})
            let langAndVersion = {language: resLang.language, version: resLang.version}
            dispatch({ type: 'CHANGE_LANGUAGE', payload: langAndVersion})
        }
    })
    .catch(err => {console.log(err)})
}

// handle users groups and users for filtering data
const handleUserGroupsFilter = (group, userInfo) => {
    let userOrgGroups = []
    const recursiveUserGroupFunction = (group) => {
        if(!group.user) {
            userOrgGroups.push({
                groupId: group.id,
                organizationId: group.organizationId,
                groupName: group.name,
                groupUsers: [{
                    id: userInfo.id,
                    name: userInfo.name,
                }]
            })
        } else {
            userOrgGroups.push({
                groupId: group.id,
                organizationId: group.organizationId,
                groupName: group.name,
                groupUsers: group.user
            })
        }
        if(group.children) {
            for(let c of group.children) {
                recursiveUserGroupFunction(c)
            }
        }
    }

    for(let u of group) {
        if(u.children !== 'blocked') recursiveUserGroupFunction(u)
    }

    let departmentOptions = []
    for(let u of userOrgGroups) {
        u.value = u.groupName
        u.label = u.groupName
        if(u.groupUsers.length > 0) {
            for(let g of u.groupUsers) {
                g.value = g.name
                g.label = g.name
            }
            u.groupUsers.unshift({id: 0, name: 'All', value: 'All', label: 'All'})
        }
        departmentOptions.push(u)
    }
    departmentOptions.unshift({groupId: 0, name: 'All', groupUsers: [{id: 0, name: 'All', value: 'All', label: 'All'}], value: 'All', label: 'All'})

    return departmentOptions
}

// handle user all users in one array
// const handleUserAllUsers = (userGroupFilter) => {
//     let users = []
//     for(let u of userGroupFilter) {
//         if(u.hasOwnProperty('groupUsers') && u.groupUsers.length > 0) {
//             for(let uu of u.groupUsers) users.push(uu)
//         }
//     }
//     users = _.uniqBy(users, 'id')
//     return users
// }

// profil check token expire
export async function checkUserToken(userId, username, email, token) {
    // console.log(userId, username, email, token)
    let response = await axios.get('/auth/check_protected', {})
    return await response.data.data
}