import React, { useState } from 'react';
import * as Styled from "./style";

import * as palette from '../../style/Variables';

import AlertModal from '../../components/alert/AlertModal'
// import brokenSign from '../../img/AnywhereBroken.png';
import brokenSign from '../../img/profil.jpeg';

const AuthAlert = () => {
    const [ locationPath ] = useState(window.location.pathname)
    const handleBackNavigation = () => {
		if(locationPath !== '/') {
			window.history.go(-1)
		}
    }
    return(
        <Styled.AuthAlert>
            <AlertModal
                icon={ brokenSign }
                title="Error 404"
                text="The requested URL was not found on this server, please return back to previous page!"
                onConfirm={() => handleBackNavigation()}
                confirmBtnText={palette.laArrowLeft}
            />
        </Styled.AuthAlert>
    )
}

export default AuthAlert;